<template>
  <section>
    <b-card no-body id="surgery-product-card" class="mb-2">
      <b-card-header class="header pt-3 pb-1" role="tab">
        <h5>Mat/med a voltar para o estoque</h5>
        <b-button v-b-toggle.stock-products-accordion variant="link">
          Ver menos
        </b-button>
      </b-card-header>
      <b-collapse id="stock-products-accordion" visible accordion="stock-products-accordion" role="tabpanel">
        <b-card-body class="pt-2">
          <SearchSurgeryItems @select="addSurgeryItem"/>
          <section class="table-container" v-if="items?.length || loading">
            <SurgeryItemsTable
              :items="items"
              :loading="loading"
              @remove="removeItem"
              @moveToUsed="moveToUsed"
            />
          </section>
        </b-card-body>
        <b-card-footer class="footer">
          <b-button variant="link" @click="openEntryModal">
            Voltar produtos ao estoque
          </b-button>
        </b-card-footer>
      </b-collapse>
    </b-card>

    <WarehouseEntryModal
      :entry="null"
      :openBillToPayProductModal="openBillToPayProductModal"
      :defaultTransactions="entryItems"
      @reloadBatches="onTransaction"
    />
  </section>
</template>

<script>
export default {
  name: 'UsedProducts',

  components: {
    SearchSurgeryItems: () => import('@/components/SurgeryCenter/Products/SearchSurgeryItems.vue'),
    SurgeryItemsTable: () => import('@/components/SurgeryCenter/Products/SurgeryItemsTable.vue'),
    WarehouseEntryModal: () => import('@/components/Warehouse/WarehouseEntryModal'),
  },

  props: {
    surgeryId: { type: String, required: true },
    items: { type: Array, default: () => [] },
    loading: Boolean,
  },
  
  data: () => ({
    entryItems: [],
  }),
  
  methods: {
    onTransaction () {
      this.$emit('onTransaction')
    },
    removeItem (id) {
      this.$emit('remove', id)
    },
    moveToUsed (id) {
      this.$emit('moveToUsed', id)
    },
    handleSetEntryItems () {
      const productTransactions = this.items.filter(
        item => item.item_type.includes('WarehouseProductTransaction') && !item.transaction_id
      ).map(item => {
          return { ...item.item, type: 'ENTRANCE', surgery_item_id: item.id }
      })

      const kitProductTransactions = this.items.filter(
        item => item.item_type.includes('Kit') && !item.transaction_id
      ).map(item => {
        return item.item.kit_products.map(kitProduct => {
          return {
            surgery_item_id: item.id,
            batch: kitProduct?.batch?.batch,
            expiration_date: this.moment(kitProduct?.expiration_date),
            manufacturing_date: null,
            product_id: kitProduct?.product_id,
            product: kitProduct?.product,
            quantity: kitProduct.quantity,
            purchase_price: kitProduct?.cost,
            type: 'ENTRANCE',
            sale_price: kitProduct?.selling_cost,
          }
        })
      }).flat()

      this.entryItems = [...productTransactions, ...kitProductTransactions]
    },
    openEntryModal () {
      this.handleSetEntryItems()
      this.$bvModal.show('warehouse-entry-modal')
    },
    async addSurgeryItem (item) {
      try {
        await this.api.createSurgeryItem({
          surgery_information_id: this.surgeryId,
          item_id: item.id,
          item_type: item.type,
          situation: 'return',
        })
        this.$toast.success(`${item.type === 'kit' ? 'Kit' : 'Produto'} adicionado com sucesso!`)
        this.$emit('update')
        this.item = null
      } catch (err) {
        this.$toast.error(err.message)
      }
    },
    openBillToPayProductModal(billToReceive) {
      this.billToPay = billToReceive;
      this.$bvModal.show('bill-to-pay-product-modal');
    },
  },
}
</script>

<style lang="scss" src="./SurgeryItems.scss" />
